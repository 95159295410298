// JavaScript Document

/* Scripts written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

// fixes media querries in Internet Explorer
if (navigator.userAgent.match(/IEMobile/)) {
	var msViewportStyle = document.createElement("style");
	msViewportStyle.appendChild(
		document.createTextNode(
			"@-ms-viewport{width:auto!important}"
		)
	);
	document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
}

/* Navigation Tweaks */

// possibly find this when the ul gets a max height of 0.
var mobileWidth = 767;

// fixes drop downs in Android & iOS
if (((navigator.userAgent.toLowerCase().indexOf("android") > -1) || (navigator.userAgent.match(/(iPad)/g))) && jQuery(window).width() > mobileWidth) {
	jQuery(document).ready(function() {
		jQuery("header nav ul li ul, header nav ul li ul li ul").parent("li").children("a").each(function() {
			var touched = false;
			jQuery(this).click(function(e) {
				if (touched === true) {
				} else {
					e.preventDefault();
					touched = true;
				}
			});
			jQuery(this).mouseleave(function() {
				touched = false;
			});
		});
		// things to note:
		// 1. If a user has a mouse attached to an Android device, this may
		//    not work as expected. More research is required to determine
		//    if I can check between Android click events and hover events.
	});
}

// fixes drop downs in Windows (Internet Explorer)
function ariaHaspopupEnabler() {
	if (!navigator.userAgent.match(/IEMobile/)) {
		jQuery("header nav ul li ul, header nav ul li ul li ul").each(function() {
			jQuery(this).parent("li").children("a").attr("aria-haspopup","true");
		});
	}
}
function ariaHaspopupDisabler() {
	if (!navigator.userAgent.match(/IEMobile/)) {
		jQuery("header nav ul li ul, header nav ul li ul li ul").each(function() {
			jQuery(this).parent("li").children("a").attr("aria-haspopup","false");
		});
	}
}
jQuery(document).ready(function() {
	ariaHaspopupEnabler();
});
jQuery(window).resize(function() {
	if (jQuery(window).width() > mobileWidth) {
		ariaHaspopupEnabler();
	} else {
		ariaHaspopupDisabler();
	}
});

// inserts labels and checkboxes
var dropDownNumber = 0;
var labelText = "";
jQuery("header nav ul, header nav ul li ul").each(function() {
	dropDownNumber = dropDownNumber + 1;
	if (dropDownNumber == 1) {
		labelText = "Menu";
	} else {
		labelText = "More";
	}
	jQuery(this).before("<label for=\"dropdown" + dropDownNumber + "\"><em>" + labelText + "</em></label>");
	jQuery(this).before("<input id=\"dropdown" + dropDownNumber + "\" type=\"checkbox\" />");
});

jQuery("#dropdown1").change(function() {
	if (jQuery(this).is(":checked")) {
		jQuery("header > nav").addClass("open-menu");
	} else {
		jQuery("header > nav").removeClass("open-menu");
    }
});
/* End Navigation Tweaks */
